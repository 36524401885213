import { ExchangeFragment } from 'fragments/exchange/exchange.fragment';
import { ExchangeSuccessFragment } from 'fragments/exchange/fragments/exchange-success/exchange-success.fragment';
import { ExchangeErrorFragment } from 'fragments/exchange/fragments/exchange-error/exchange-error.fragment';
import { ExchangeEmptyFragment } from 'fragments/exchange/fragments/exchange-empty/exchange-empty.fragment';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { AuthRedirectFragment } from 'fragments/auth-redirect/auth-redirect.fragment';

export const AppRoutes: React.FC<any> = () => {
    return (
        <div>
            <Routes>
                <Route path={'/auth'} element={<AuthRedirectFragment />} />
                <Route path={'/redeem'} element={<ExchangeFragment />} />
                <Route path={'/redeem/success'} element={<ExchangeSuccessFragment />} />
                <Route path={'/redeem/error'} element={<ExchangeErrorFragment />} />
                <Route path={'/redeem/empty'} element={<ExchangeEmptyFragment />} />
                <Route path={'*'} element={<Navigate to={'/redeem'} replace />} />
            </Routes>
        </div>
    );
};
