import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TranslatableString } from 'tools/view-hooks/translator-hook';
import { ExchangeErrorController } from './interfaces';

export const useExchangeErrorController = ():
ExchangeErrorController => {
    const qs = require('qs');
    const location = useLocation();

    const [errorTitle, setErrorTitle] = useState<TranslatableString>({ key: 'exchange-error.title-default' });
    const [errorSubtitle, setErrorSubtitle] = useState<TranslatableString>({ key: 'exchange-error.subtitle-default' });

    useEffect( () => {
        const errorId: string = qs.parse(location.search, { ignoreQueryPrefix: true}).error;
        if (errorId === undefined) {
            return;
        }

        switch(errorId){
            case 'count':
                setErrorTitle({ key: 'exchange-error.title-count' });
                setErrorSubtitle({ key: 'exchange-error.subtitle-count' });
                break;
            case 'expired':
                setErrorTitle({ key: 'exchange-error.title-expired' });
                setErrorSubtitle({ key: 'exchange-error.subtitle-expired' });
                break;
            case 'disabled':
                setErrorTitle({ key: 'exchange-error.title-disabled' });
                setErrorSubtitle({ key: 'exchange-error.subtitle-disabled' });
                break;
            default:
                break;
        }

    }, []);
    return { errorTitle, errorSubtitle };
};
