import React from 'react';
import 'fragments/main/main.scss';
import { MainFragmentProps } from 'fragments/main/interfaces';
import { useMainController } from 'fragments/main/main.controller';
import { Layout } from 'antd';
import { AppBarFragment } from 'fragments/app-bar/app-bar.fragment';
import { SidePanelFragment } from 'navigators/side-panel/side-panel.fragment';
import { AppRoutes } from 'fragments/main/routes/app-routes';
import MainProvider from 'fragments/context/main/main-provider.component';
import { useLocalSession } from 'auth/helpers/session.hooks';
const { Content, Footer, Header, Sider } = Layout;

export const MainFragment: React.FC<MainFragmentProps> = (props) => {
    const { useController = useMainController } = props;
    const controller = useController();
    const [session] = useLocalSession();

    // Render
    return (
        <div className={'main'}>
            <MainProvider>
                <div>
                    <AppRoutes />
                </div>
            </MainProvider>
        </div>
    );
};
