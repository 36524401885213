

import React from 'react';
import 'fragments/exchange/exchange.scss';
import { ExchangeFragmentProps } from 'fragments/exchange/interfaces';
import { useExchangeController } from 'fragments/exchange/exchange.controller';
import { Button, Col, Form, Input, Row, Layout, Typography, Spin } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Header } = Layout;

export const ExchangeFragment: React.FC<ExchangeFragmentProps> = (props) => {
    const { useController = useExchangeController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    
    return (
        <div className={'exchange'}>
            <Header className={'exchange-header'}>
                <img src={'logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <Col flex={'600px'}>
                    <Form
                        name="basic"
                        layout={'vertical'}
                        className={'exchange-form'}
                        initialValues={{ remember: true }}
                    >
                        <Form.Item>
                            <Title level={2}>{translate({ key: 'exchange.title' })}</Title>
                            <Text type={'secondary'} style={{ fontSize: '14pt' }}>
                                {translate({ key: 'exchange.subtitle' })}
                            </Text>
                        </Form.Item>
                        <Form.Item>
                            <Spin size={'large'} />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};