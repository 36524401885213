

import { useState } from 'react';
import { ExchangeEmptyController } from './interfaces';

export const useExchangeEmptyController = ():
ExchangeEmptyController => {

    return {};
};
