

import React from 'react';
import './exchange-error.scss';
import { ExchangeErrorFragmentProps } from './interfaces';
import { useExchangeErrorController } from './exchange-error.controller';
import { Button, Col, Form, Input, Row, Layout, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Header } = Layout;

export const ExchangeErrorFragment: React.FC<ExchangeErrorFragmentProps> = (props) => {
    const { useController = useExchangeErrorController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'exchange-error'}>
            <Header className={'exchange-error-header'}>
                <img src={'../logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <Col flex={'600px'}>
                    <Form
                        name="basic"
                        layout={'vertical'}
                        className={'exchange-error-form'}
                        initialValues={{ remember: true }}
                        // onFinish={controller.onexchange-errorSubmit}
                    >
                        <Title level={2}>{translate(controller.errorTitle)}</Title>
                        <Text type={'secondary'} style={{ fontSize: '14pt' }}>
                            {translate(controller.errorSubtitle)}
                        </Text>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
