

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExchangeController } from 'fragments/exchange/interfaces';
import { useAPISitePolicies } from 'services/site-policies/api-site-policies.service';
import { ErrorDto } from 'services/dtos/errors..dto';
import { SitePolicyRedeemRequest } from 'services/site-policies/site-policies.service';

export const useExchangeController = (
): ExchangeController => {
    const qs = require('qs');
    const location = useLocation();
    const navigate = useNavigate();
    const apiSitePolicies = useAPISitePolicies();

    useEffect(() => {
        onPageLoaded();
    }, []);
    
    const exchangeSitePolicy = (request: SitePolicyRedeemRequest, token: string) => {
        apiSitePolicies
            .redeemSitePolicy(request, token)
            .then(() => {
                navigate('/redeem/success');
            })
            .catch((error: ErrorDto) => {
                if (error.response.status == 409) {
                    navigate(`/redeem/error?error=${error.response.data.msg}`);
                    return;
                }
                //TODO handle error
                navigate('/redeem/error');
            });
    };

    const onPageLoaded = () => {
        const code: string = qs.parse(location.search, { ignoreQueryPrefix: true}).code;
        if (code === undefined) {
            navigate('/redeem/empty');
            return;
        }

        const token: string = qs.parse(location.search, { ignoreQueryPrefix: true }).token;
        if (token === undefined) {
            //redirect to web-auth
            navigate('/auth?code=' + code);
            return;
        }

        exchangeSitePolicy({code: code}, token);
    };

    return {}
};
