

import { useState } from 'react';
import { ExchangeSuccessController } from './interfaces';

export const useExchangeSuccessController = ():
ExchangeSuccessController => {

    return { };
};
