

import {
    SitePolicies,
    SitePolicyRedeemRequest,
    SitePolicyRedeemResponse,
} from 'services/site-policies/site-policies.service';
import axios from 'axios';
import { ErrorDto } from 'services/dtos/errors..dto';

export const useAPISitePolicies = (): SitePolicies => {
    const redeemSitePolicy = async (input: SitePolicyRedeemRequest, token: string): Promise<SitePolicyRedeemResponse> => {
        try{
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/redeem',
            input,
            {
                headers: { Authorization: 'Bearer ' + token },
            });
            return response.data as SitePolicyRedeemResponse;
        } catch (err: unknown) {
            const error = err as ErrorDto;
            throw error;
        }
    };

    return {
        redeemSitePolicy,
    };
};
