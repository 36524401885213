import { useEffect } from 'react';
import { AuthRedirectController } from 'fragments/auth-redirect/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';

export const useAuthRedirectController = ():AuthRedirectController => {
    const location = useLocation();
    const qs = require('qs');
    const navigate = useNavigate();
    
    useEffect(() => {
        const code: string = qs.parse(location.search, { ignoreQueryPrefix: true}).code;
        if (code === undefined) {
            navigate('/redeem/empty');
            return;
        }
        window.location.replace(process.env.REACT_APP_AUTH_MODULE_URL! + "?url=" + "https://qr.accefy.io/redeem?code=" + code);
    }, []);

    return {};
};
