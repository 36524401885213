import React, { useState } from 'react';
import { MainContext } from 'fragments/context/main/main.context';

type Props = {
    children?: React.ReactNode;
};

const MainProvider: React.FC<Props> = (props) => {
    return <MainContext.Provider value={{}}>{props.children}</MainContext.Provider>;
};

export default MainProvider;
