

import React from 'react';
import './exchange-empty.scss';
import { ExchangeEmptyFragmentProps } from './interfaces';
import { Button, Col, Form, Input, Row, Layout, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { useExchangeEmptyController } from './exchange-empty.controller';
const { Text, Title } = Typography;
const { Header } = Layout;

export const ExchangeEmptyFragment: React.FC<ExchangeEmptyFragmentProps> = (props) => {
    const { useController = useExchangeEmptyController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'exchange-empty'}>
            <Header className={'exchange-empty-header'}>
                <img src={'../logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <Col flex={'600px'}>
                    <Form
                        name="basic"
                        layout={'vertical'}
                        className={'exchange-empty-form'}
                        initialValues={{ remember: true }}
                    >
                        <Title level={2}>{translate({ key: 'exchange-empty.title' })}</Title>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
