

import React from 'react';
import './exchange-success.scss';
import { ExchangeSuccessFragmentProps } from './interfaces';
import { useExchangeSuccessController } from './exchange-success.controller';
import { Button, Col, Form, Input, Row, Layout, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Header } = Layout;

export const ExchangeSuccessFragment: React.FC<ExchangeSuccessFragmentProps> = (props) => {
    const { useController = useExchangeSuccessController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    // Render
    return (
        <div className={'exchange-success'}>
            <Header className={'exchange-success-header'}>
                <img src={'../logo-bco.png'} className={'header-img'} />
            </Header>
            <Row justify={'center'}>
                <Col flex={'600px'}>
                    <Form
                        name="basic"
                        layout={'vertical'}
                        className={'exchange-success-form'}
                        initialValues={{ remember: true }}
                        // onFinish={controller.onexchange-successSubmit}
                    >
                        <Title level={2}>{translate({ key: 'exchange-success.title' })}</Title>
                        <Text type={'secondary'} style={{ fontSize: '14pt' }}>
                            {translate({ key: 'exchange-success.subtitle' })}
                        </Text>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
