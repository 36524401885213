import React, { useContext } from 'react';
import { Session } from 'auth/helpers/session';

// export interface MainContextInterface {}

export const MainContext = React.createContext<Record<string, never>>({});

export const useMainContext = (): Record<string, never> => {
    const store = useContext(MainContext);
    return store;
};
