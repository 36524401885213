

import React from 'react';
import 'fragments/auth-redirect/auth-redirect.scss';
import { AuthRedirectFragmentProps } from 'fragments/auth-redirect/interfaces';
import { useAuthRedirectController } from 'fragments/auth-redirect/auth-redirect.controller';

export const AuthRedirectFragment: React.FC<AuthRedirectFragmentProps> = (props) => {
    const { useController = useAuthRedirectController } = props;
    useController();

    return <div className={'auth-redirect'}></div>;
};
